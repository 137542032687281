export const addBlankToExternalLinks = (content: string, selfHost: string) => {
  const updatedContent = content.replace(
    new RegExp(
      `<a\\s+([^>]*?href=["'](https?:\\/\\/(?!${selfHost})[^"']+)["'][^>]*)>`,
      'gi'
    ),
    '<a $1 target="_blank">'
  )

  return updatedContent
}
